@import "../../assets/styles/color";

.loader{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: $c_white_alt;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;


.loading {
    border: 16px solid;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }

@keyframes animloader {
    0% {
      border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75);
}
    33% {
      border-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35);
}
    66% {
      border-color: rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
}
    100% {
      border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15);
}
  }
}
  
  