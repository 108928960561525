@import "../../assets/styles/color";
@import "../../assets/styles/spacing";
@import "../../assets/styles/breakpoint";

.header{
    padding: $sp_4 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo{
        font-size: 28px;
        line-height: 32px;
        color: $c_black;
    }

    & nav{
        & div{
            font-size: 18px;
            line-height: 22px;
            color: $c_black;
            cursor: pointer;
        }
    }
}