@import "../../assets/styles/color";
@import "../../assets/styles/spacing";
@import "../../assets/styles/breakpoint";

.container{
    width: 100%;
    max-width: $bp_wide;
    margin: 0 auto;
    padding: 0 $sp_3;
}

.hero{
    margin: $sp_16 auto;
    max-width: 35%;
    width: 100%;
    text-align: center;
    color: $c_black;

    @media (max-width: $bp_desktop) {
        max-width: 55%;
    }
    @media (max-width: $bp_tablet) {
        max-width: 65%;
        margin: $sp_8 auto;
    }
    @media (max-width: $bp_mobile) {
        max-width: 100%;
    }
}