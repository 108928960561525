@import "./breakpoint";

* {
    outline: none;
    box-sizing: border-box;
}
a{
    text-decoration: none;
}

h1{
    font-size: 42px;
    line-height: 46px;
    font-weight: normal;

    @media (max-width: $bp_tablet) {
        font-size: 32px;
        line-height: 36px;
    }
}

img{
    width: 100%;
    margin: 0;
    display: flex;
}