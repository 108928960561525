@import "./color";
@import "./spacing";
@import "./breakpoint";
@import "./font";


.project{
    &__header{
        margin: $sp_16 auto;
        text-align: center;
        max-width: 50%;
        width: 100%;

        @media (max-width: $bp_tablet) {
            max-width: 100%;
            margin: $sp_8 auto;
        }

        & h1{
            margin: 0;
        }
        & p{
            margin: $sp_2 0 0 0;
            font-size: 20px;
            line-height: 24px;
            color: $c_black;
            font-family: $f_light_alt;

            @media (max-width: $bp_tablet) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    &__main{
        margin: $sp_8 0;

        & > div{
            margin-top: $sp_1;

            &:nth-of-type(1){
                margin-top: 0;
            }
        }
    }
}

