.asset{
  position: relative;
  aspect-ratio: 1.79;
  object-fit: cover;
  width: 100%;

  & img{
    aspect-ratio: 1.79;
  object-fit: cover;
  }
}