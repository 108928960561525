@import "../../assets/styles/color";
@import "../../assets/styles/spacing";
@import "../../assets/styles/breakpoint";

.footer{
    margin: $sp_16 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: $bp_tablet) {
        margin: $sp_8 0;
    }

    & a{
        font-size: 28px;
        line-height: 32px;
        margin: $sp_2 0;
        color: $c_black;

        &:nth-of-type(1){
            margin-top: 0;
        }
        &:nth-last-of-type(1){
            margin-bottom: 0;
        }

        @media (max-width: $bp_tablet) {
            font-size: 24px;
            line-height: 28px;
        }
        @media (max-width: $bp_mobile) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &_email{}
}