@import "./font";

// Font Faces
@font-face {
    font-family: $f_light;
    src:
        url('../fonts/ppeditorialnew-light-webfont.woff2') format('woff2'),
        url('../fonts/ppeditorialnew-light-webfont.woff') format('woff');
}

@font-face {
    font-family: $f_light_alt;
    src:
        url('../fonts/haasgrotdisp-45light-webfont.woff2') format('woff2'),
        url('../fonts/haasgrotdisp-45light-webfont.woff') format('woff');
}