@import "./reset";
@import "./font-face";
@import "./color";
@import "./spacing";
@import "./breakpoint";

body, html{
    background: $c_white;
    font-family: $f_light;
    margin: 0;
}

.projects-showcase{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $sp_8 0;

    @media (max-width: $bp_tablet) {
        flex-direction: column;
    }
}

.projects-list{
    width: 50%;
    display: flex;
    padding-right: $sp_4;
    flex-direction: column;
    
    @media (max-width: $bp_tablet) {
        width: 100%;
        align-items: center;
        text-align: center;
        padding-right: 0;
    }

    &__item{
        font-size: 42px;
        line-height: 46px;
        border-bottom: 2px solid transparent;
        // transition: .2s;
        color: $c_black;
        margin: $sp_1 0;
        padding-bottom: $sp_1;
        position: relative;

        @media (max-width: $bp_tablet) {
            font-size: 28px;
            line-height: 32px;
            padding-bottom: 0;
            width: 100%;
        }

        & .asset{
            display: none;

            @media (max-width: $bp_tablet) {
                display: flex;
                margin-bottom: $sp_1;
            }
        }

        @media (max-width: $bp_tablet) {
            & span{
                width: 100%;
                padding: $sp_3;
            }
        }

        &:nth-of-type(1){
            margin-top: 0;
        }
        &:nth-last-of-type(1){
            margin-bottom: 0;
        }

        @media (min-width: $bp_tablet) {
            &:hover{
                font-style: italic;
                border-color: $c_black;
                
            }
        }
    }
}

.projects-images{
    position: relative;
    width: 50%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    background: rgba($color: #000000, $alpha: 0.05);

    @media (max-width: $bp_tablet) {
        display: none;
    }

    &__item{
        position: absolute;
        opacity: 0;
        transition: .6s;

        & .asset{
            height: 100%;
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;

            & > img{
                aspect-ratio: 1 / 1;
            object-fit: cover;
            }
        }

        &--active{
            // display: block;
            // height: auto;
            opacity: 1;
        }
    }
}